<template>
    <div :class="['absolute h-2 w-2 md:h-3.5 md:w-3.5', main ? '' : '']">
        <span class="flex">
            <span
                :class="[
                    'animate-ping absolute inline-flex bg-white h-full w-full opacity-75',
                    main ? 'rounded-[3px]' : 'rounded-full',
                ]"
            >
            </span>
            <span
                :class="[
                    'relative inline-flex h-2 w-2 md:h-3.5 md:w-3.5 ring-white ring-inset ring-1 cursor-pointer z-10',
                    main ? 'rounded-[3px]' : 'rounded-full',
                    isOpen
                        ? 'bg-secondary-500'
                        : 'bg-secondary-500/70 hover:bg-secondary-500/90',
                ]"
                ref="inner"
                @click="openInfo"
            >
            </span>
        </span>

        <BranchDotPopover
            :name="name"
            :phone="phone"
            :cep="cep"
            :address="address"
            :maps-link="mapsLink"
            v-model="openPopover"
        />

        <BranchDotDialog
            :name="name"
            :phone="phone"
            :cep="cep"
            :address="address"
            :maps-link="mapsLink"
            v-model="openDialog"
        />

        <!-- First Try Popover -->
        <transition
            enter-active-class="transition duration-200 ease-out"
            enter-from-class="translate-y-1 opacity-0"
            enter-to-class="translate-y-0 opacity-100"
            leave-active-class="transition duration-150 ease-in"
            leave-from-class="translate-y-0 opacity-100"
            leave-to-class="translate-y-1 opacity-0"
        >
            <div
                class="
                    absolute
                    bottom-[18px]
                    -right-[90px]
                    w-48
                    flex
                    items-center
                    bg-gray-800
                    shadow
                    rounded-lg
                    z-40
                "
                v-show="main && isFirstTry"
            >   
                <div class="relative p-4 after:absolute after:top-full after:left-1/2 after:-ml-2 after:border-8 after:border-solid after:border-transparent after:border-t-gray-800 after:pointer-events-none">
                    <p class="text-white text-center text-base font-semibold select-none">
                        Clique para ver as informações da filial
                    </p>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import BranchDotDialog from "./BranchDotDialog";
import BranchDotPopover from "./BranchDotPopover";

// import tippy from "tippy.js";

export default {
    components: {
        BranchDotDialog,
        BranchDotPopover,
    },

    props: ["main", "name", "phone", "cep", "address"],

    data: () => ({
        tippy: null,
        openDialog: false,
        openPopover: false,
        isFirstTry: false,
    }),

    mounted() {
        if (window.innerWidth >= 1024) {
            this.$nextTick(() => (this.isFirstTry = true))
        }

        // this.tippy = tippy(this.$refs.inner, {
        //     content: "Clique para mais informações",
        // });
    },

    // watch: {
    //     isOpen(newVal) {
    //         if (newVal) {
    //             this.tippy.disable();
    //         } else {
    //             this.tippy.enable();
    //         }
    //     },
    // },

    methods: {
        openInfo() {
            // Trying to solve better-scroll double click problem (Using Dialog cooldown)
            // NOTE: Not used anymore
            // e.preventDefault();
            // e.stopPropagation();

            this.isFirstTry = false;

            if (window.innerWidth < 1024) {
                this.openDialog = !this.openDialog;
            } else {
                this.openPopover = !this.openPopover;
            }
        },
    },

    computed: {
        isOpen() {
            return this.openDialog || this.openPopover;
        },

        mapsLink() {
            let link = "https://www.google.com/maps/place/";

            link += this.address
                .map((addr) => encodeURIComponent(addr))
                .join("+");

            if (this.cep) {
                link += ",+" + encodeURIComponent(this.cep);
            }

            return link;
        },
    },
};
</script>

