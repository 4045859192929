<template>
    <div class="relative bg-white overflow-hidden">
        <div class="relative">
            <BackgroundVideo />

            <div class="pb-40 lg:pb-32">
                <NavbarSection
                    :sections="sections"
                    :client-portal="clientPortal"
                    :is-sticky="isSticky"
                    ref="navSection"
                />

                <MainSection
                    :is-sticky="isSticky"
                    :client-portal="clientPortal"
                />
            </div>
        </div>

        <div class="relative">
            <ServicesAndSectorsSection :services="services" :items="items" />

            <ShippingSection :shippings="shippings" />

            <AboutSection />

            <ContactSection :contacts="contacts" />

            <FooterSection />
        </div>
    </div>
</template>

<script>
import BackgroundVideo from "@/components/BackgroundVideo";

import NavbarSection from "./sections/NavbarSection";
import MainSection from "./sections/MainSection";
import ServicesAndSectorsSection from "./sections/ServicesAndSectorsSection";
import ShippingSection from "./sections/ShippingSection";
import AboutSection from "./sections/AboutSection";
import ContactSection from "./sections/ContactSection";
import FooterSection from "./sections/FooterSection";

import {
    InboxInIcon,
    CubeTransparentIcon,
    SparklesIcon,
    ShieldExclamationIcon, 
    AtSymbolIcon,
AcademicCapIcon,
BanIcon
} from "@heroicons/vue/outline";


export default {
    components: {
        BackgroundVideo,
        NavbarSection,
        MainSection,
        ServicesAndSectorsSection,
        ShippingSection,
        AboutSection,
        ContactSection,
        FooterSection,
    },

    methods: {
        goToElement(id) {
            document.getElementById(id).scrollIntoView({
                behavior: "smooth",
            });
        },
    },

    mounted() {
        window.onscroll = () => {
            if(window.innerWidth < 1024) {
                this.isSticky = false
                return
            }

            if (window.pageYOffset > this.$refs.navSection.$refs.navbar.offsetTop) {
                this.isSticky = true
            } else {
                this.isSticky = false
            }
        }
    },

    data: () => ({
        isSticky: false,

        clientPortal: "http://smonet.tecmartransportes.com.br",

        sections: [
            { name: "Início", id: "main" },
            { name: "Serviços", id: "services-and-sectors" },
            { name: "Nossa Frota", id: "shipping" },
            { name: "Sobre a Empresa", id: "about" },
        ],

        services: [
            {
                name: "Armazenagem",
                description: "A nossa ampla rede de armazéns em todo o país nos permite estar preparados para desenvolver projetos de armazenagem adaptados às necessidades de cada cliente. Além disso, contamos com um robusto sistema de WMS que entrega todos os dados necessários para a gestão do seu estoque e distribuição.",
                icon: CubeTransparentIcon,
            },
            {
                name: "Rodocabotagem",
                description: "Somos pioneiros na oferta deste serviço, que consiste na integração inteligente do transporte rodoviário e cabotagem, tendo apenas um operador logístico da origem ao destino. Alguns dos benefícios são a redução da emissão de CO², otimização do deslocamento, redução de custos, além do aumento da integridade e e rastreabilidade da carga.",
                icon: SparklesIcon,
            },
            {
                name: "Transporte  de Contêiner ",
                description:
                    "Realizamos o transporte de cargas conteinerizadas, simplificando o transporte de grandes volumes de mercadorias e a integração com outros modais.",
                icon: InboxInIcon,
            },
            {
                name: "Cargas Fracionadas e Lotação",
                description:
                    "Com uma das maiores redes de distribuição logística do país e frota própria de veículos, conduzimos seus produtos em formato fracionado ou de lotação com segurança, flexibilidade e monitoramento em tempo real.",
                icon: AcademicCapIcon,
            },
            
        ],

        items: [
            {
                name: "Auto-peças",
                image: "/img/sectors/car.jpg",
            },
            {
                name: "Cosméticos",
                image: "/img/sectors/cosmetic.jpg",
            },
            {
                name: "Confecção & Calçados",
                image: "/img/sectors/clothing.jpg",
            },
            {
                name: "Alimentos",
                image: "/img/sectors/food.jpg",
            },
            {
                name: "Higiene & Limpeza",
                image: "/img/sectors/cleaning.jpg",
            },
            {
                name: "Armazenagem",
                image: "/img/sectors/storage.jpg",
            },
        ],

        shippings: [
            {
                name: "BiTrem",
                description:
                    "Oferecendo um comprimento máximo de 30 metros, peso de até 74 toneladas e tendo o total de sete eixos. Compoe uma categoria de caminhões que circulam com grandes quantidades de cargas em estradas.",
                explanation:
                    "O bitrem nada mais é que a junção de dois modelos dos tipos de semireboque (a parte de carga) unidos entre si. Essa união acontece por meio de uma espécie de “quinta roda”, que fica localizada no fundo do primeiro veículo que fará a união do conjunto. Todos esses elementos juntos são tracionados por um modelo de cavalo mecânico.",
                image: "/img/shipping/bitrem.webp",
            },
            {
                name: "BiTruck",
                description:
                    "O bitruck (8x2 ou 8x4) tem oito pontos de contato com o solo e quatro eixos, sendo dois dianteiros direcionais. Por ter quatro eixos, o bitruck tem uma capacidade maior de transporte e mais capacidade que o trucado e peso bruto total de 29 toneladas. São, por exemplo, caminhões graneleiros e caminhões-tanque.",
                explanation:
                    " Esse tipo de caminhão dispõe de quatro eixos. Sua capacidade de ca máxima é de 22 mil toneladas e o PBT é de 29 toneladas.É muito utilizado para transportar cargas secas, mas  é empregado em diferentes operações de logística, sendo bastante versátil.",
                image: "/img/shipping/bi-truck.webp",
            },
            {
                name: "Truck",
                description:
                    "Com comprimento de 14 metros, o Veículo também possui um melhor desempenho nas estradas. É uma categoria que geralmente não é visto circulando pelas cidades, sendo mais comum nas estradas.",
                explanation:
                    "Conhecido também como trucado ou caminhão 6×2, o caminhão truck possui um conjunto de eixos misto. Sendo um simples (uma roda de cada lado) na frente e outro duplo atrás (duas rodas de cada lado). A configuração permite que o veículo tenha uma capacidade de peso bruto total de 19.5 toneladas. Considerando que o eixo duplo atua diretamente na força do motor.",
                image: "/img/shipping/truck.webp",
            },
            {
                name: "VUC",
                description:
                    "Tendo um comprimento máximo 6,30 metros e peso de até 3 toneladas. Faz parte de uma categoria de caminhões semi-leves e leves que são aptos para entregas urbanas nas regiões centrais das cidades.",
                explanation:
                    "VUC (Veículo Urbano de Carga). Esse é o termo usado para uma categoria de caminhões semi-leves e leves que são aptos para entregas urbanas nas regiões centrais das cidades. Por estas características, eles podem estacionar mais fácil e também possuem permissões especiais que outros caminhões não têm, tendo a capacidade de até 3 toneladas.",
                image: "/img/shipping/vuc.webp",
            },
        ],

        contacts: [
            {
                name: "E-mail",
                hrefName: "comercial@tecmartransportes.com.br",
                href: null,
                description:
                    "Entre em contato com o nosso time comercial via e-mail.",
                icon: AtSymbolIcon,
            },
            {
                name: "Relação de Taxas 2024",
                hrefName: "Clique aqui e tenha acesso",
                href: "/lista-tde.pdf",
                description: "Relação de Taxas 2024 TDE, Veículo dedicado, descarga, agendamento e paletização ",
                icon: BanIcon,
            },
            {
                name: "Canal de Denúncia",
                hrefName: "Acesse o Canal",
                href: "https://www.canaldedenuncia.com.br/grupologin/",
                description: "Relate o descumprimentos do Manual de Conduta Ética por meio do  Canal de Denúncia.",
                icon: ShieldExclamationIcon,
            },
            {
                name: "Relatorio de Transparencia salarial",
                hrefName: "Acesso o documento ",
                href: "/transparencia.pdf",
                description: "Em cumprimento à Lei nº 14.611/2023, tornamos público o acesso ao relatório de transparência salarial disponibilizado pelo Ministério do Trabalho.",
                icon: BanIcon,
            },
        ],
    }),
};
</script>

