<template>
    <svg
        viewBox="0 0 93 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
       
    >
        <path d="M0.5 24H61.5L93 0L0.5 24Z" />
    </svg>
</template>

<script>
export default {};
</script>