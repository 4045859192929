<template>
    <footer class="bg-white">
        <div
            class="
                max-w-7xl
                mx-auto
                py-12
                px-4
                space-y-4
                md:space-y-0
                sm:px-6
                md:flex md:items-center md:justify-between
                lg:px-8
            "
        >
            <p class="text-center text-base text-gray-600">
                &copy; 2001 - {{ currentYear }} TECMAR Transportes. Todos os direitos reservados.
            </p>
            <p class="text-center flex justify-center text-base text-gray-600">
                Uma empresa 
                <a class="ml-2.5" href="https://www.loginlogistica.com.br/" target="_blank">
                    <img class="h-8" src="/img/logo-login.png" alt="LogIn Logistica" />
                </a>
            </p>
        </div>
    </footer>
</template>

<script>
export default {
    computed: {
        currentYear() {
            return new Date().getFullYear()
        }
    }
};
</script>