<template>
    <div id="about" class="relative pt-24 lg:pb-16 bg-white border-t">
        <!-- <div
            class="hidden absolute top-0 inset-x-0 h-1/2 bg-gray-50 lg:block"
            aria-hidden="true"
        /> -->
        <div class="max-w-7xl mx-auto bg-indigo-900 lg:bg-transparent lg:px-8">
            <div class="lg:grid lg:grid-cols-12">
                <div
                    class="
                        relative
                        z-10
                        lg:col-start-1
                        lg:row-start-1
                        lg:col-span-4
                        lg:py-40
                        lg:bg-transparent
                    "
                >
                    <div
                        class="absolute inset-x-0 h-1/2 bg-gray-50 lg:hidden"
                        aria-hidden="true"
                    />
                    <div
                        class="
                            max-w-md
                            mx-auto
                            px-4
                            sm:max-w-3xl sm:px-6
                            lg:max-w-none lg:p-0
                        "
                    >
                        <div
                            class="
                                aspect-w-10 aspect-h-6
                                sm:aspect-w-2 sm:aspect-h-1
                                lg:aspect-w-1
                            "
                        >
                            <img
                                class="
                                    object-cover object-center
                                    rounded-3xl
                                    shadow-2xl
                                    ring-1
                                    ring-opacity-5
                                    ring-black
                                "
                                src="/img/about-image.png"
                                alt=""
                            />
                        </div>
                    </div>
                </div>

                <div
                    class="
                        relative
                        bg-indigo-900
                        lg:col-start-3
                        lg:row-start-1
                        lg:col-span-10
                        lg:rounded-3xl
                        lg:grid
                        lg:grid-cols-10
                        lg:items-center
                    "
                >
                    <div
                        class="
                            hidden
                            absolute
                            inset-0
                            overflow-hidden
                            rounded-3xl
                            lg:block
                        "
                        aria-hidden="true"
                    >
                        <svg
                            class="
                                absolute
                                bottom-full
                                left-full
                                transform
                                translate-y-1/3
                                -translate-x-2/3
                                xl:bottom-auto xl:top-0 xl:translate-y-0
                            "
                            width="404"
                            height="384"
                            fill="none"
                            viewBox="0 0 404 384"
                            aria-hidden="true"
                        >
                            <defs>
                                <pattern
                                    id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                                    x="0"
                                    y="0"
                                    width="20"
                                    height="20"
                                    patternUnits="userSpaceOnUse"
                                >
                                    <rect
                                        x="0"
                                        y="0"
                                        width="4"
                                        height="4"
                                        class="text-indigo-900"
                                        fill="currentColor"
                                    />
                                </pattern>
                            </defs>
                            <rect
                                width="404"
                                height="384"
                                fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
                            />
                        </svg>
                        <svg
                            class="
                                absolute
                                top-full
                                transform
                                -translate-y-1/3 -translate-x-1/3
                                xl:-translate-y-1/2
                            "
                            width="404"
                            height="384"
                            fill="none"
                            viewBox="0 0 404 384"
                            aria-hidden="true"
                        >
                            <defs>
                                <pattern
                                    id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                                    x="0"
                                    y="0"
                                    width="20"
                                    height="20"
                                    patternUnits="userSpaceOnUse"
                                >
                                    <rect
                                        x="0"
                                        y="0"
                                        width="4"
                                        height="4"
                                        class="text-primary-500"
                                        fill="currentColor"
                                    />
                                </pattern>
                            </defs>
                            <rect
                                width="404"
                                height="384"
                                fill="url(#4e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
                            />
                        </svg>
                    </div>
                    <div
                        class="
                            relative
                            max-w-md
                            mx-auto
                            py-12
                            px-4
                            sm:max-w-3xl sm:py-16 sm:px-6
                            lg:max-w-none lg:px-0 lg:col-start-4 lg:col-span-6
                        "
                    >
                        <h2
                            class="mb-6 text-3xl tracking-tight font-bold text-white"
                        >
                            Sobre a Empresa
                        </h2>

                        <div class="space-y-6">
                            <p class="text-lg text-white">
                                Com o inicío das nossas operações em outubro de 2001, contamos com uma bagagem de mais de 22 anos de experiência no transporte rodoviário de cargas fracionadas. Nossos colaboradores estão focados em prover a melhor solução logística para seu negócio. Ofertamos também gestão de armazenagens complementada por soluções logísticas de distribuição.
                            </p>

                            <p class="text-lg text-white">
                                Em 2022 a Tecmar passou a fazer parte do Grupo Log-In, um dos maiores grupos logísticos brasileiros focado na Cabotagem. Com a incorporação, a Tecmar além de ofertar todas as soluções já conhecidas via transporte rodoviário de carga, passa também a disponibilizar aos seus clientes, soluções inovadoras envolvendo a Multimodalidade. A possibilidade de transportar a carga fracionada utilizando a Cabotagem já é uma realidade.
                            </p>
                        </div>

                        <hr class="border-white/60 my-10" />

                        <div class="space-y-6">
                            <div class="flex" v-for="topic in list" :key="topic.title">
                                <div class="flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-lg bg-white/20 ring-white/20 ring-inset ring-1 text-white">
                                    <component
                                        :is="topic.icon"
                                        class="h-6 w-6"
                                        aria-hidden="true"
                                    />
                                </div>

                                <div class="ml-4">
                                    <p class="text-xl leading-6 font-semibold text-white">{{ topic.title }}</p>
                                    <p class="text-base text-white">
                                       {{ topic.description }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    FlagIcon,
    EyeIcon,
    HandIcon
} from "@heroicons/vue/outline";

export default {
    data: () => ({
        list: [
            {
                title: 'Nossa Missão',
                description: 'Ser eficiente em todos os processos e contribuir para o sucesso dos nossos clientes, oferecendo serviços de altíssima qualidade e gerando lucratividade aos acionistas.',
                icon: FlagIcon,
            },
            {
                title: 'Nossa Visão',
                description: 'Ser referência no segmento de transportes no Brasil, sinônimo de inovação, agilidade, qualidade e competência.',
                icon: EyeIcon,
            },
            {
                title: 'Nossos Valores',
                description: 'Nossos Valores são a base do nosso negócio e temos orgulho em afirmar que, antes de tudo, somos pessoas éticas na vida pessoal e profissional.',
                icon: HandIcon,
            }
        ],
    })
};
</script>