<template>
    <BrazilMapRaw class="w-full shadow-map">
        <!-- Non-supported Regions -->
        <g class="shadow-gray fill-gray-100 stroke-gray-400">
            <AcRegion />
            <ApRegion />
            <DfRegion />
            <MsRegion />
            <MtRegion />
            <PaRegion />
            
            <RoRegion />
            <ToRegion />
            
        </g>

        <!-- Supported Regions -->
        <!-- <g class="shadow-red z-10 fill-primary-500 stroke-primary-200 hover:[&>*]:fill-primary-400"> -->
        <g class="
                z-10
                fill-[#a1a1aa]
                stroke-[#ccd4ff]
                hover:[&>*]:fill-[#7188f7]
            "
        >
            <AlRegion />
            <AmRegion />
            <BaRegion />
            <CeRegion />
            <EsRegion />
            <GoRegion />
            <MaRegion />
            <MgRegion />
            <PbRegion />
            <PeRegion />
            <PiRegion />
            <PrRegion />
            <RjRegion />
            <RrRegion />
            <RnRegion />
            <RsRegion />
            <ScRegion />
            <SeRegion />
            <SpRegion />
        </g>

        <!-- Dummy elements to avoid shadow overflow -->
        <g class="fill-gray-100 stroke-gray-400">
            <AcRegion />
            <PaRegion />
            <MsRegion />
            <MtRegion />
            <RjRegion />
            <RoRegion />
            <ToRegion />
        </g>

        <!-- Dummy elements to avoid shadow overflow -->
        <g class="
            z-10
            fill-[#a1a1aa]
            stroke-[#ccd4ff]
            hover:[&>*]:fill-[#7188f7]
            "
        >
        <AmRegion />
        </g>
        
        <!-- Overlay shadow -->
        <DfRegion class="fill-gray-100" />
    </BrazilMapRaw>

    <!-- Branch dots -->
    <BranchDot
        :main="branch.main"
        :name="branch.name"
        :phone="branch.phone"
        :cep="branch.cep"
        :address="branch.address"
        :style="`top: ${branch.pos[0]}%;left: ${branch.pos[1]}%`"
        v-for="branch in branches"
        :key="branch.name"
    />
</template>

<style scoped>
@media (min-width: 1024px) {
    .shadow-map {
        filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04))
            drop-shadow(0 4px 3px rgb(0 0 0 / 0.2));
    }
}

.shadow-gray {
    filter: drop-shadow(1px 1px 0px #9d9d9d) drop-shadow(2px 2px 0px #9d9d9d)
        drop-shadow(3px 3px 0px #9d9d9d);
}

.shadow-red {
    filter: drop-shadow(1px 1px 0px #2543db) drop-shadow(2px 2px 0px #2543db)
        drop-shadow(3px 3px 0px #2543db);
    /* filter: drop-shadow(1px 1px 0px #dc2626) drop-shadow(2px 2px 0px #dc2626)
        drop-shadow(3px 3px 0px #dc2626); */
}
</style>

<script>
/* eslint-disable */

import Branches from "@/infos/branches";

import BranchDot from "./BranchDot";
import BrazilMapRaw from "./BrazilMapRaw";

import AcRegion from "./regions/AcRegion";
import AlRegion from "./regions/AlRegion";
import AmRegion from "./regions/AmRegion";
import ApRegion from "./regions/ApRegion";
import BaRegion from "./regions/BaRegion";
import CeRegion from "./regions/CeRegion";
import DfRegion from "./regions/DfRegion";
import EsRegion from "./regions/EsRegion";
import GoRegion from "./regions/GoRegion";
import MaRegion from "./regions/MaRegion";
import MgRegion from "./regions/MgRegion";
import MsRegion from "./regions/MsRegion";
import MtRegion from "./regions/MtRegion";
import PaRegion from "./regions/PaRegion";
import PbRegion from "./regions/PbRegion";
import PeRegion from "./regions/PeRegion";
import PiRegion from "./regions/PiRegion";
import PrRegion from "./regions/PrRegion";
import RjRegion from "./regions/RjRegion";
import RnRegion from "./regions/RnRegion";
import RoRegion from "./regions/RoRegion";
import RrRegion from "./regions/RrRegion";
import RsRegion from "./regions/RsRegion";
import ScRegion from "./regions/ScRegion";
import SeRegion from "./regions/SeRegion";
import SpRegion from "./regions/SpRegion";
import ToRegion from "./regions/ToRegion";

export default {
    components: {
        BranchDot,
        BrazilMapRaw,
        AcRegion,
        AlRegion,
        AmRegion,
        ApRegion,
        BaRegion,
        CeRegion,
        DfRegion,
        EsRegion,
        GoRegion,
        MaRegion,
        MgRegion,
        MsRegion,
        MtRegion,
        PaRegion,
        PbRegion,
        PeRegion,
        PiRegion,
        PrRegion,
        RjRegion,
        RnRegion,
        RoRegion,
        RrRegion,
        RsRegion,
        ScRegion,
        SeRegion,
        SpRegion,
        ToRegion,
    },

    computed: {
        branches() {
            return Branches;
        },
    },
};
</script>
