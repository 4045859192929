<template>
    <div class="absolute w-full h-full">
        <div class=" bg-indigo-900 mix-blend-multiply absolute w-full h-full z-10" />

        <div class="relative w-full h-full">
            <img src="/background-video-fallback.jpg" class="object-cover bg-cover	w-full h-full absolute block top-0 left-0" />

            <video class="object-cover bg-cover	w-full h-full absolute block top-0 left-0" muted autoplay loop>
                <source src="/background-video-cropped.mp4" type="video/mp4" />

                <img src="/background-video-fallback.jpg" title="Your browser does not support the <video> tag" />
            </video>
        </div>
    </div>
</template>

<script>
export default {};
</script>