<template>
    <svg
        xmlns:mapsvg="http://mapsvg.com"
        xmlns:dc="http://purl.org/dc/elements/1.1/"
        xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
        xmlns:svg="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 620 640"
        mapsvg:geoViewBox="-74.008595 5.275696 -34.789914 -33.743888"
    >
        <slot />
    </svg>
</template>
