<template>
    <div
        :class="[
            'transition-all duration-150 ease-in-out',
            isSticky
                ? 'fixed top-0 left-0 right-0 bg-white z-40 shadow'
                : 'bg-transparent',
        ]"
    >
        <nav
            :class="[
                'relative max-w-7xl px-4 sm:px-6 z-20 py-4 mb-4 border-b mx-4 xl:mx-auto',
                'flex flex-col justify-center items-center space-y-3 lg:space-y-0 lg:flex-row lg:items-center lg:justify-between',
                isSticky ? 'border-gray-500/40' : 'border-white/40',
            ]"
            aria-label="Global"
            ref="navbar"
        >
            <span
                :class="[
                    'flex items-center font-medium text-sm',
                    isSticky ? 'text-gray-800' : 'text-white',
                ]"
            >
                <PhoneIcon class="w-5 h-5 mr-2" />
                (11) 3238-1400 / 3613-5922
            </span>

            <div class="flex">
                <div :class="[
                    'space-x-3 flex pr-4 mr-4 border-r',
                    isSticky ? 'border-gray-500/40' : 'border-white/40'
                ]">
                    <a href="https://www.linkedin.com/company/tecmar-transportes-ltda" target="_blank" :class="isSticky ? 'fill-secondary-800 hover:fill-secondary-900' : 'fill-gray-50 hover:fill-white'">
                        <LinkedInIcon class="w-5 h-5" />
                    </a>
                    <a href="https://instagram.com/tecmar.transportes?igshid=YmMyMTA2M2Y=" target="_blank" :class="isSticky ? 'fill-secondary-800 hover:fill-secondary-900' : 'fill-gray-50 hover:fill-white'">
                        <InstagramIcon class="w-5 h-5" />
                    </a>
                    <a href="https://www.facebook.com/tecmar.oficial/" target="_blank" :class="isSticky ? 'fill-secondary-800 hover:fill-secondary-900' : 'fill-gray-50 hover:fill-white'">
                        <FacebookIcon class="w-5 h-5" />
                    </a>
                    <a href="https://www.youtube.com/@tecmartransportesltda.2422" target="_blank" :class="isSticky ? 'fill-secondary-800 hover:fill-secondary-900' : 'fill-gray-50 hover:fill-white'">
                        <YoutubeIcon class="w-5 h-5" />
                    </a>
                </div>

                <a
                    :href="clientPortal"
                    target="_blank"
                    :class="[
                        'items-center font-medium text-sm flex',
                        isSticky
                            ? 'text-lime-600 hover:text-lime-700'
                            : 'text-gray-50 hover:text-white',
                    ]"
                >
                    <DocumentSearchIcon class="w-5 h-5 mr-2" />
                    Área do Cliente
                </a>
            </div>
        </nav>
        <Popover>
            <nav
                class="
                    relative
                    max-w-7xl
                    mx-auto
                    flex
                    items-center
                    justify-between
                    px-4
                    sm:px-6
                    z-20
                    mb-4
                "
                aria-label="Global"
            >
                <div class="flex items-center justify-between flex-1">
                    <div
                        class="
                            flex
                            items-center
                            justify-between
                            w-full
                            lg:w-auto
                        "
                    >
                        <a href="#">
                            <span class="sr-only">Tecmar Transportes</span>
                            <img
                                class="h-16 w-auto sm:h-20 -my-3"
                                :src="
                                    isSticky
                                        ? '/img/logo.png'
                                        : '/img/logo-white.png'
                                "
                                alt=""
                            />
                        </a>
                        <div class="-mr-2 flex items-center lg:hidden">
                            <PopoverButton
                                :class="[
                                    'rounded-md p-2 inline-flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500',
                                    isSticky
                                        ? 'text-gray-800 hover:text-gray-700'
                                        : 'text-gray-200 hover:text-gray-100 hover:bg-white/10',
                                ]"
                            >
                                <span class="sr-only">Open main menu</span>
                                <MenuIcon class="h-6 w-6" aria-hidden="true" />
                            </PopoverButton>
                        </div>
                    </div>
                    <div
                        class="
                            hidden
                            lg:ml-10 lg:space-x-10 lg:flex
                            items-center
                        "
                    >
                        <a
                            v-for="item in sections"
                            :key="item.name"
                            :class="[
                                'font-medium cursor-pointer',
                                isSticky
                                    ? 'text-gray-800 hover:text-gray-700'
                                    : 'text-gray-100 hover:text-white',
                            ]"
                            @click="$root.goToElement(item.id)"
                        >
                            {{ item.name }}</a
                        >
                        <TButtonTransparent
                            class="cursor-pointer"
                            @click="$root.goToElement('contact')"
                            :inverted="isSticky"
                        >
                            <MailIcon class="w-5 h-5 mr-2" />
                            Entre em Contato
                        </TButtonTransparent>
                    </div>
                </div>
            </nav>

            <transition
                enter-active-class="duration-150 ease-out"
                enter-from-class="scale-95"
                enter-to-class="scale-100"
                leave-active-class="duration-100 ease-in"
                leave-from-class="opacity-100 scale-100"
                leave-to-class="opacity-0 scale-95"
            >
                <PopoverPanel
                    focus
                    class="
                        absolute
                        z-40
                        top-0
                        inset-x-0
                        p-2
                        transition
                        transform
                        origin-top-right
                        lg:hidden
                    "
                >
                    <div
                        class="
                            rounded-lg
                            shadow-md
                            bg-gray-900/30
                            ring-white/30 ring-inset ring-1
                            overflow-hidden
                            backdrop-blur-lg
                        "
                    >
                        <div class="px-5 pt-4 flex items-start justify-between">
                            <div>
                                <img
                                    class="h-16 w-auto"
                                    src="/img/logo-white.png"
                                    alt="Tecmar Transportes"
                                />
                            </div>
                            <div class="-mr-2">
                                <PopoverButton
                                    class="
                                        rounded-md
                                        p-2
                                        inline-flex
                                        items-center
                                        justify-center
                                        text-gray-200
                                        hover:text-gray-100 hover:bg-white/10
                                        focus:outline-none
                                        focus:ring-2
                                        focus:ring-inset
                                        focus:ring-primary-500
                                    "
                                >
                                    <span class="sr-only">Close main menu</span>
                                    <XIcon class="h-6 w-6" aria-hidden="true" />
                                </PopoverButton>
                            </div>
                        </div>
                        <div class="px-2 pt-2 pb-3 space-y-1">
                            <a
                                v-for="item in sections"
                                :key="item.name"
                                @click="$root.goToElement(item.id)"
                                class="
                                    block
                                    px-3
                                    py-2
                                    rounded-md
                                    text-base
                                    font-medium
                                    text-gray-100
                                    hover:text-white
                                    cursor-pointer
                                "
                                >{{ item.name }}</a
                            >
                        </div>
                        <a
                            @click="$root.goToElement('contact')"
                            class="
                                flex
                                justify-center
                                items-center
                                w-full
                                px-5
                                py-3
                                text-center
                                font-semibold
                                text-white
                                bg-transparent
                                hover:bg-white/10
                                border-t border-white/30
                                cursor-pointer
                            "
                        >
                            <MailIcon class="w-6 h-6 mr-2" /> Entre em Contato
                        </a>
                    </div>
                </PopoverPanel>
            </transition>
        </Popover>
    </div>
</template>

<script>
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import {
    MenuIcon,
    XIcon,
    PhoneIcon,
    DocumentSearchIcon,
    MailIcon,
} from "@heroicons/vue/outline";

import TButtonTransparent from "@/components/TButtonTransparent";

import FacebookIcon from "@/icons/FacebookIcon";
import InstagramIcon from "@/icons/InstagramIcon";
import LinkedInIcon from "@/icons/LinkedInIcon";
import YoutubeIcon from "@/icons/YoutubeIcon";

export default {
    props: ["sections", "clientPortal", "isSticky"],

    components: {
        Popover,
        PopoverButton,
        PopoverPanel,
        MenuIcon,
        XIcon,
        PhoneIcon,
        DocumentSearchIcon,
        MailIcon,
        TButtonTransparent,
        FacebookIcon,
        InstagramIcon,
        LinkedInIcon,
        YoutubeIcon,
    },
};
</script>
¨