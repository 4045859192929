<template>
    <main 
        id="main" 
        :class="[
            'mx-auto max-w-7xl px-4 sm:px-6 relative z-30', 
            isSticky ? 'pt-[221px] md:pt-[205px]' : 'pt-16'
        ]"
    >
        <div class="lg:grid lg:grid-cols-12 lg:gap-16">
            <div
                class="
                    sm:text-center
                    md:max-w-2xl md:mx-auto
                    lg:col-span-5 lg:text-left
                    flex
                    items-center
                "
            >
                <div class="block">
                    <a
                        href="/manual-de-conduta-etica.pdf"
                        target="_blank"
                        class="
                            inline-flex
                            items-center
                            bg-gray-400/50
                            rounded-full
                            p-1
                            pr-2
                            sm:text-base
                            lg:text-sm
                            xl:text-base
                            font-medium
                            text-white
                            hover:bg-gray-400/70
                            transition
                            ease-in-out
                            duration-150
                        "
                    >
                        <span
                            class="
                                px-3
                                py-0.5
                                text-white text-xs
                                font-semibold
                                leading-5
                                uppercase
                                tracking-wide
                                bg-lime-600
                                rounded-full
                            "
                            >Acesse
                        </span>
                        <span class="ml-4 text-sm"
                            >Manual de Conduta Ética
                        </span>
                        <ChevronRightIcon
                            class="ml-2 w-5 h-5 text-white"
                            aria-hidden="true"
                        />
                    </a>
                    <h1 class="mt-4">
                        <span
                            class="
                                mt-1
                                block
                                text-4xl
                                tracking-tight
                                font-extrabold
                                sm:text-5xl
                                xl:text-6xl
                            "
                        >
                            <span class="block text-white"
                                >Transporte sua carga
                            </span>
                            <span class="block text-lime-600"
                                >com segurança</span
                            >
                        </span>
                    </h1>
                    <p
                        class="
                            mt-3
                            text-base text-white
                            sm:mt-5 sm:text-xl
                            lg:text-lg
                            xl:text-xl
                        "
                    >
                        As melhores soluções logísticas via transporte rodoviário de carga, com 52 filiais, sendo 30 parceiros, estrategicamente posicionadas em 18 estados brasileiros.
                    </p>
                    <div
                        class="
                            mt-8
                            sm:max-w-lg sm:mx-auto sm:text-center
                            lg:text-left lg:mx-0
                            space-x-4
                            flex
                            justify-start
                            sm:justify-center
                            lg:justify-start
                        "
                    >
                        <TButtonTransparent
                            class="cursor-pointer"
                            @click="$root.goToElement('contact')"
                        >
                            <MailIcon class="w-5 h-5 mr-2" />
                            Entre em Contato
                        </TButtonTransparent>
                        <TButtonTransparent :href="clientPortal" target="_blank">
                            <DocumentSearchIcon class="w-5 h-5 mr-2" />
                            Área do Cliente
                        </TButtonTransparent>
                    </div>
                </div>
            </div>
            <div
                class="
                    mt-12
                    relative
                    sm:max-w-lg sm:mx-auto
                    lg:mt-0
                    lg:max-w-none
                    lg:mx-0
                    lg:col-span-7
                    lg:flex
                    lg:items-center
                "
            >
                <div
                    class="
                        relative
                        w-full
                        overflow-hidden
                        rounded-xl
                        lg:rounded-none lg:overflow-visible
                    "
                    id="bs-wrapper"
                >
                    <!-- Re-container the map, Trick to not break everything with zoom -->
                    <div class="relative w-full">
                        <BrazilMapContainer />
                    </div>
                </div>

                <div class="flex lg:hidden justify-center">
                    <div
                        class="
                            bg-white bg-gray-400/50
                            rounded-full
                            py-1
                            px-2
                            flex
                            space-x-3
                            justify-center
                            items-center
                            mt-12
                        "
                    >
                        <InformationCircleIcon class="w-8 h-8 text-white" />

                        <span class="text-base text-white font-medium">
                            Utilize o Zoom para clicar em uma das filiais para
                            mais informações!
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import BrazilMapContainer from "@/brazil-map/BrazilMapContainer";

import {
    ChevronRightIcon,
    DocumentSearchIcon,
    MailIcon,
} from "@heroicons/vue/solid";

import { InformationCircleIcon } from "@heroicons/vue/outline";

import TButtonTransparent from "@/components/TButtonTransparent";


export default {
    props: ["branches", "clientPortal", "isSticky"],

    components: {
        TButtonTransparent,
        BrazilMapContainer,
        ChevronRightIcon,
        MailIcon,
        DocumentSearchIcon,
        InformationCircleIcon,
    },
};
</script>
