<template>
    <div
        class="
            relative
            w-full
            overflow-hidden
            rounded-xl
            md:rounded-none md:overflow-visible
        "
        id="bs-wrapper"
    >
        <!-- Re-container the map, Trick to not break everything with zoom -->
        <div class="relative w-full">
            <BrazilMap />
        </div>
    </div>
</template>

<script>
import BrazilMap from './BrazilMap'

import BScroll from "@better-scroll/core";
import Zoom from "@better-scroll/zoom";

BScroll.use(Zoom);

export default {
    components: {
        BrazilMap
    },

    mounted() {
         const bs = new BScroll("#bs-wrapper", {
            freeScroll: true,
            scrollX: true,
            scrollY: true,
            // bindToTarget: true, // ????
            preventDefault: false,
            click: true,
            zoom: {
                start: 1,
                min: 1,
                max: 3.5,
            },
            // Optimizations
            bounce: false,
            momentum: false,
        });

        const hooks = bs.scroller.actionsHandler.hooks;

        // Isso aqui é meio doido. Vamos por partes
        // O objetivo era fazer com que caso o mapa não tenha scroll, ou seja, ele não sofreu zoom ou chegou no final
        // O evento tinha que ser mandado para a pagina, para o usuario conseguir fazer arrastar a pagina pra baixo clicando na area do mapa
        // Para isso acontecer, foi desabilitado o "preventDefault", ou seja. Com essa opção, o scroll iria funcionar para o elemento e pagina ao mesmo tempo
        // Então eu criei um hook, para a cada clique, se o scroll não estiver no final, ele previne a pagina de fazer scroll, fazendo só no objeto
        // Assim conseguindo o resultado que eu queria, só que fazendo de uma forma invertida

        // Atualização 1: Se o evento de touch tiver mais de 1 touch, quer dizer que é zoom
        // Então, obrigatoriamenete ele vai estar alterando o map

        // Atualizaçao 2: A logica inicial foi comentada. Agora se o mapa estiver com zoom,
        // Obrigatoriamente vai controlar somente o mapa. A logica inicial estava dando muito problema na hora arrastar
        hooks.on("start", (event) => {
            if(event.touches === undefined) {// not touch
                return
            }

            if (event.touches.length > 1) {
                event.preventDefault();
                return;
            }

            // if (bs.maxScrollY !== bs.y && bs.minScrollX !== bs.y) {
            //     event.preventDefault()
            //     return
            // }

            if (bs.maxScrollY !== 0) {
                event.preventDefault();
                return;
            }
        });
    }
};
</script>
