<template>
    <div id="shipping" class="relative overflow-hidden pt-32 pb-24 xl:pt-24">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative">
            <div class="max-w-2xl sm:mx-auto sm:text-center xl:max-w-none">
                <h2
                    class="
                        font-bold font-display
                        text-3xl
                        tracking-tight
                        text-indigo-900/100
                        sm:text-4xl
                        md:text-5xl
                    "
                >
                    Conheça nossa frota
                </h2>
                <p class="mt-6 text-lg tracking-tight text-indigo-900/100">
                    São mais de 2.000 coletas e entregas diárias, cobrindo cerca de 2.600 municípios brasileiros.
                </p>
            </div>
            <div
                class="
                    mt-8
                    grid grid-cols-1
                    items-center
                    gap-y-2
                    pt-10
                    sm:gap-y-6
                    lg:mt-20
                    lg:grid-cols-12 lg:pt-0
                "
            >
                <div
                    class="
                        -mx-4
                        flex
                        overflow-x-auto
                        pb-4
                        sm:mx-0 sm:overflow-visible sm:pb-0
                        lg:col-span-5
                    "
                >
                    <div
                        class="
                            relative
                            z-10
                            flex
                            gap-x-4
                            whitespace-nowrap
                            px-4
                            sm:mx-auto sm:px-0
                            lg:mx-0
                            lg:block
                            lg:gap-x-0
                            lg:gap-y-1
                            lg:whitespace-normal
                        "
                        role="tablist"
                        aria-orientation="vertical"
                    >
                        <div
                            :class="[
                                'group relative rounded-full py-2 px-3 lg:rounded-r-none lg:rounded-l-xl lg:p-6',
                                'transition-all ease-in-out duration-100',
                                selectedShipping === shipping
                                    ? 'bg-indigo-900 lg:ring-2 lg:ring-inset lg:ring-white/30 shadow'
                                    : '',
                            ]"
                            v-for="shipping in shippings"
                            :key="shipping.name"
                            @click="selectedShipping = shipping"
                        >
                            <h3>
                                <button
                                    :class="[
                                       'font-display text-xl flex items-center font-semibold [\:not(:focus-visible)]:focus:outline-none',
                                        selectedShipping === shipping
                                            ? 'text-white'
                                            : 'text-gray-800'
                                    ]"
                                    role="tab"
                                    type="button"
                                    aria-selected="true"
                                    tabindex="0"
                                >

                                    <span
                                        class="
                                            absolute
                                            inset-0
                                            rounded-full
                                            lg:rounded-r-none lg:rounded-l-xl
                                        "
                                    >
                                    </span>
                                    <div :class="[
                                        'rounded-full ring-inset ring-1 p-2 mr-3'
                                        , selectedShipping === shipping
                                            ? 'bg-white/20 ring-white/20'
                                            : 'bg-gray-300/20 ring-gray-600/20'
                                    ]">
                                        <TruckIcon class="w-6 h-6" />
                                    </div>
                                    {{ shipping.name }}
                                </button>
                            </h3>
                            <p
                                :class="[
                                'mt-2 hidden text-base lg:block',
                                 selectedShipping === shipping
                                 ? 'text-white'
                                 : 'text-gray-600'
                                ]"
                            >
                                {{ shipping.description }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="lg:col-span-7 -ml-1 z-10">
                    <div
                        class="
                            mt-10
                            bg-secondary-50 
                            w-auto
                            lg:mt-0 
                            relative
                            lg:h-[46rem]
                        "
                    >
                        <img class="lg:absolute lg:max-w-none lg:h-[46rem] shadow-xl shadow-primary-900/20 rounded-t-xl sm:rounded-xl" :src="selectedShipping.image" />
                        <div class="sm:absolute left-0 bottom-0 p-4 md:p-6 lg:px-8 rounded-b-xl sm:rounded-xl lg:-mr-10 sm:bg-indigo-900">
                            <p class="text-white text-base sm:text-lg md:text-xl lg:text-2xl font-medium">
                                {{ selectedShipping.explanation }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { TruckIcon } from "@heroicons/vue/outline"

export default {
    props: ["shippings"],

    components: {
        TruckIcon
    },

    data: () => ({
        selectedShipping: "",
    }),

    created() {
        this.selectedShipping = this.shippings[0];
    },
};
</script>
