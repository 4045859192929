<template>
    <TransitionRoot as="template" :show="modelValue">
        <Dialog as="div" class="relative z-40" @close="close">
            <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in duration-200"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div
                    class="
                        fixed
                        inset-0
                        bg-gray-500 bg-opacity-75
                        transition-opacity
                    "
                />
            </TransitionChild>

            <div class="fixed z-10 inset-0 overflow-y-auto">
                <div
                    class="
                        flex
                        items-center
                        justify-center
                        min-h-full
                        p-4
                        text-center
                        sm:p-0
                    "
                >
                    <TransitionChild
                        as="template"
                        enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <DialogPanel
                            class="
                                relative
                                bg-white
                                rounded-lg
                                px-4
                                pt-5
                                pb-4
                                text-left
                                overflow-hidden
                                shadow-xl
                                transform
                                transition-all
                                sm:my-8 sm:max-w-lg
                                w-full
                                sm:p-6
                            "
                        >
                            <div
                                class="
                                    hidden
                                    sm:block
                                    absolute
                                    top-0
                                    right-0
                                    pt-4
                                    pr-4
                                "
                            >
                                <button
                                    type="button"
                                    class="
                                        bg-white
                                        rounded-md
                                        text-gray-400
                                        hover:text-gray-500
                                        focus:outline-none
                                        focus:ring-2
                                        focus:ring-offset-2
                                        focus:ring-primary-500
                                    "
                                    @click="close"
                                >
                                    <span class="sr-only">Close</span>
                                    <XIcon class="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>
                            <div class="sm:flex sm:items-start">
                                <div
                                    class="
                                        mx-auto
                                        flex-shrink-0 flex
                                        items-center
                                        justify-center
                                        h-12
                                        w-12
                                        rounded-full
                                        bg-primary-100
                                        sm:mx-0 sm:h-10 sm:w-10
                                    "
                                >
                                    <LocationMarkerIcon
                                        class="h-6 w-6 text-primary-600"
                                        aria-hidden="true"
                                    />
                                </div>
                                <div
                                    class="
                                        mt-3
                                        text-center
                                        sm:mt-0 sm:ml-4 sm:text-left
                                    "
                                >
                                    <DialogTitle
                                        as="h3"
                                        class="
                                            text-lg
                                            leading-6
                                            font-medium
                                            text-gray-900
                                        "
                                    >
                                        {{ name }}
                                    </DialogTitle>
                                    <p class="text-sm text-gray-500">
                                        Informações sobre a filial selecionada.
                                    </p>
                                    <div class="mt-5 space-y-5">
                                        <div>
                                            <p
                                                class="
                                                    text-sm
                                                    font-medium
                                                    text-gray-600
                                                "
                                            >
                                                Endereço
                                            </p>
                                            <p
                                                class="text-sm text-gray-500"
                                                v-for="addressLine in address"
                                                :key="addressLine"
                                            >
                                                {{ addressLine }}
                                            </p>
                                        </div>

                                        <div>
                                            <p
                                                class="
                                                    text-sm
                                                    font-medium
                                                    text-gray-600
                                                "
                                            >
                                                CEP
                                            </p>
                                            <p class="text-sm text-gray-500">
                                                {{ cep }}
                                            </p>
                                        </div>

                                        <div>
                                            <p
                                                class="
                                                    text-sm
                                                    font-medium
                                                    text-gray-600
                                                "
                                            >
                                                Telefone
                                            </p>
                                            <p class="text-sm text-gray-500">
                                                {{ phone || "Não informado" }}
                                            </p>
                                        </div>

                                        <div
                                            class="
                                                flex
                                                justify-center
                                                space-x-2
                                            "
                                        >
                                            <TButtonWhite @click="close">
                                                Fechar
                                            </TButtonWhite>
                                            <TButtonWhite
                                                :href="mapsLink"
                                                target="_blank"
                                            >
                                                <GoogleMapsIcon
                                                    class="w-5 h-5 mr-2"
                                                />
                                                Acesse no Maps
                                            </TButtonWhite>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import TButtonWhite from "@/components/TButtonWhite";

import GoogleMapsIcon from "@/icons/GoogleMapsIcon";

import {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
import { LocationMarkerIcon, XIcon } from "@heroicons/vue/outline";

export default {
    components: {
        Dialog,
        DialogPanel,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        LocationMarkerIcon,
        XIcon,
        TButtonWhite,
        GoogleMapsIcon,
    },

    props: ["modelValue", "name", "phone", "cep", "address", "mapsLink"],

    data: () => ({
        cooldown: null,
    }),

    // Trying to solve better-scroll double click problem
    watch: {
        modelValue(newVal) {
            if (newVal === true) {
                this.cooldown = true;
                setTimeout(() => (this.cooldown = false), 100);
            }
        },
    },

    methods: {
        close() {
            if (this.cooldown) {
                return;
            }

            this.$emit("update:modelValue", false);
        },
    },
};
</script>
