<template>
    <transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="translate-y-1 opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="transition duration-150 ease-in"
        leave-from-class="translate-y-0 opacity-100"
        leave-to-class="translate-y-1 opacity-0"
    >
        <div
            class="
                absolute
                right-1/2
                z-40
                mt-1
                -mr-2
                w-screen
                transform
                px-4
                sm:px-0
                max-w-sm
            "
            v-if="modelValue"
        >
            <div
                class="
                    overflow-hidden
                    rounded-lg
                    shadow-lg
                    ring-1 ring-black ring-opacity-5
                "
            >
                <div
                    class="
                        bg-gray-50
                        flex
                        px-4
                        py-3
                        justify-between
                        items-center
                    "
                >
                    <div class="flex items-center space-x-3">
                        <div class="p-2 rounded-full bg-primary-100">
                            <LocationMarkerIcon
                                class="h-5 w-5 text-primary-600"
                                aria-hidden="true"
                            />
                        </div>
                        <span class="font-medium text-lg text-gray-00">{{
                            name
                        }}</span>
                    </div>

                    <a :href="mapsLink" target="blank">
                        <GoogleMapsIcon class="w-6 h-6" />
                    </a>
                </div>
                <div class="relative bg-white px-4 py-4">
                    <p
                        class="text-gray-600 text-sm"
                        v-for="addressLine in address"
                        :key="addressLine"
                    >
                        {{ addressLine }}
                    </p>

                    <p class="text-gray-600 text-sm mt-3" v-if="cep">
                        <span class="font-semibold">CEP :</span> {{ cep }}
                    </p>
                </div>
                <div
                    class="bg-white flex space-x-2 px-4 py-3 border-t"
                    v-if="phone"
                >
                    <PhoneIcon class="w-5 h-5 text-gray-600" />
                    <span class="text-gray-600 text-sm">{{ phone }}</span>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import GoogleMapsIcon from "@/icons/GoogleMapsIcon";

import { PhoneIcon } from "@heroicons/vue/solid";
import { LocationMarkerIcon } from "@heroicons/vue/outline";

export default {
    components: {
        LocationMarkerIcon,
        GoogleMapsIcon,
        PhoneIcon,
    },

    props: ["modelValue", "name", "phone", "cep", "address", "mapsLink"],

    mounted() {
        setTimeout(this.toggleListener, 100)
    },

    watch: {
        modelValue() {
            this.toggleListener();
        },
    },

    methods: {
        toggleListener() {
            if (this.modelValue) {
                setTimeout(() => document.body.addEventListener("click", this.tryToClose), 100);
            } else {
                document.body.removeEventListener("click", this.tryToClose);
            }
        },

        tryToClose(event) {
            if (this.$el === event.target || this.$el.contains(event.target)) {
                return;
            }

            this.$emit("update:modelValue", false);
        },
    },
};
</script>
