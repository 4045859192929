<template>
    <div id="contact" class="bg-white">
        <!-- Header -->
        <div class="relative pb-32 bg-indigo-900">
            <div class="absolute inset-0">
                <img
                    class="w-full h-full object-cover"
                    src="/img/contact-background.jpg"
                    alt=""
                />
                <div
                    class="absolute inset-0 bg-indigo-900 mix-blend-multiply"
                    aria-hidden="true"
                />
            </div>
            <div
                class="
                    relative
                    max-w-7xl
                    mx-auto
                    py-24
                    px-4
                    sm:py-32 sm:px-6
                    lg:px-8
                "
            >
                <h1
                    class="
                        text-4xl
                        font-bold
                        tracking-tight
                        text-white
                        md:text-5xl md:tracking-tight
                        lg:text-6xl lg:tracking-tight
                    "
                >
                    Entre em Contato
                </h1>
                <p class="mt-6 max-w-3xl text-xl text-gray-300">
                    Descubra como podemos ajudar o seu negocio a ir mais longe!
                </p>
            </div>
        </div>

        <!-- Overlapping cards -->
        <section
            class="
                -mt-32
                max-w-8xl
                mx-auto
                relative
                z-10
                pb-24
                px-4
                sm:px-6
                lg:px-8
            "
            aria-labelledby="contact-heading"
        >
            <h2 class="sr-only" id="contact-heading">Entre em contato</h2>
            <div
                class="
                    grid grid-cols-1
                    gap-y-20
                    lg:grid-cols-4 lg:gap-y-0 lg:gap-x-8
                "
            >
                <div
                    v-for="link in contacts"
                    :key="link.name"
                    class="flex flex-col bg-white rounded-2xl shadow-xl"
                >
                    <div class="flex-1 relative pt-16 px-6 pb-8 md:px-5">
                        <div
                            class="
                                absolute
                                top-0
                                p-5
                                inline-block
                                bg-lime-600
                                rounded-xl
                                shadow-lg
                                transform
                                -translate-y-1/2
                            "
                        >
                            <component
                                :is="link.icon"
                                class="h-6 w-6 text-white"
                                aria-hidden="true"
                            />
                        </div>
                        <h3 class="text-xl font-medium text-indigo-900">
                            {{ link.name }}
                        </h3>
                        <p class="mt-4 text-base text-indigo-900">
                            {{ link.description }}
                        </p>
                    </div>
                    <div
                        class="
                            p-6
                            bg-gray-50
                            rounded-bl-2xl rounded-br-2xl
                            md:px-8
                        "
                    >
                        <a
                            :href="link.href"
                            target="_blank"
                            class="
                                text-base
                                font-semibold
                                text-lime-600
                                hover:text-lime-600
                            "
                        >
                            {{ link.hrefName }}<span v-if="link.href" aria-hidden="true"> &rarr;</span>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    props: ["contacts"],
};
</script>
