<template>
    <a
        :class="[
            'inline-flex text-center items-center px-4 py-2 border-2 shadow-sm text-sm font-medium rounded-md focus:outline-none',
            'transition duration-150 ease-in-out',
            inverted 
                ? 'border-gray-700 text-gray-800 hover:bg-gray-600/10 focus:bg-gray-600/20'
                : 'border-gray-100 text-white hover:bg-gray-100/20 focus:bg-gray-100/30'
        ]"
    >
        <slot />
    </a>
</template>

<script>
export default {
    props: {
        inverted: {
            type: Boolean,
            default: false,
        }
    }
};
</script>