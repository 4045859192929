<template>
    <div id="services-and-sectors" class="relative bg-indigo-900 shadow-lg py-16 z-20">
        <ArrowDetail class="h-12 md:h-16 lg:h-20 absolute -top-12 md:-top-16 lg:-top-20" />

        <ArrowDetail class="h-12 md:h-16 lg:h-20 absolute -bottom-12 md:-bottom-16 lg:-bottom-20 right-0 rotate-180" />

        <!-- Services -->
        <div class="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <dl class="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-4 lg:gap-8">
                <div v-for="service in services" :key="service.name">
                    <dt>
                        <div
                            class="
                                flex
                                items-center
                                justify-center
                                h-14
                                w-14
                                rounded-full
                                bg-white/20
                                ring-white/20 ring-inset ring-1
                                text-white
                            "
                        >
                            <component
                                :is="service.icon"
                                class="h-8 w-8"
                                aria-hidden="true"
                            />
                        </div>
                        <p
                            class="
                                mt-5
                                text-xl
                                leading-6
                                font-semibold
                                text-white
                            "
                        >
                            {{ service.name }}
                        </p>
                    </dt>
                    <dd class="mt-2 text-lg text-gray-100">
                        {{ service.description }}
                    </dd>
                </div>
            </dl>
        </div>

        <!-- Sectors -->
        <div>
            <p
                class="
                    text-2xl
                    leading-6
                    font-semibold
                    text-white text-center
                    my-12
                "
            >
                Setores de Atuação
            </p>

            <div class="relative w-auto mx-4 sm:mx-6 md:mx-8 lg:mx-12 xl:mx-20 overflow-hidden">
                <div
                    class="flex carousel-animation justify-center gap-6 -mx-60"
                >
                    <template v-for="i in [1, 2, 3]" :key="i">
                        <div
                            class="
                                flex
                                space-x-4
                                items-center
                                px-8
                                py-2
                                bg-white/20
                                ring-white/20 ring-inset ring-1
                                rounded-md
                            "
                            v-for="item in items"
                            :key="item.name"
                        >
                            <div
                                class="
                                    relative
                                    overflow-hidden
                                    border-4 border-white/40
                                    rounded-full
                                    w-16
                                    h-16
                                "
                            >
                                <img
                                    class="
                                        object-cover
                                        bg-cover
                                        w-full
                                        h-full
                                        absolute
                                        block
                                        top-0
                                        left-0
                                    "
                                    :src="item.image"
                                />
                            </div>
                            <p class="text-white font-medium text-lg break-normal min-w-max">
                                {{ item.name }}
                            </p>
                        </div>
                    </template>
                </div>

                <div class="pointer-events-none absolute inset-y-0 left-0 w-12 sm:w-16 md:w-20 lg:w-24 xl:w-32 bg-gradient-to-r from-primary-600"></div>
                <div class="pointer-events-none absolute inset-y-0 right-0 w-12 sm:w-16 md:w-20 lg:w-24 xl:w-32 bg-gradient-to-l from-primary-600"></div>
            </div>
        </div>
    </div>
</template>

<script>
import ArrowDetail from "@/components/ArrowDetail";

export default {
    props: ["services", "items"],

    components: {
        ArrowDetail,
    },
};
</script>

<style>
@keyframes carousel {
    /* from {
        right: -4126px;
        right: -66.66667%;
    }
    to {
        right: 0;
    } */
    to{
        transform: translateX(-50%)
    }
}

.carousel-animation {
    animation: carousel 30s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
</style>